/*  #35536C */


.scroll {
    position: fixed;
    top: -16px;
    width: 100%;
    transition: all 2s ease;
    z-index: 2;
}

.icon-menu {
    display: none;
}

/* Style for header */
.sectionInfoHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

.logoSection img {
    width: 350px;
    margin: 5px 10px;
}

.sectionDirection p,
.sectionDirection a {
    color: #000000;
    font-size: 0.850rem;
    font-weight: bold;
    margin: 15px 10px;
    text-decoration: none;
}

.sectionDirection a span {
    color: #00122B;
    font-size: 1.4rem;
    padding: 10px;
}

.icon-wristwatch {
    color: #00122B;
    font-size: 1.4rem;
    padding: 10px;
}

.sectionDirection a:hover {
    color: #09a903;
}

/* Style for the navegation */
.headerMenu {
    margin: 0px;
}

.MenuOptions {
    border: 0px solid #35536C;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style: none;
    text-transform: capitalize;
    background-color: #35536C;
    width: 97%;
}

.MenuOptions a {
    text-decoration: none;
    text-align: center;
}

.MenuOptions li {
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    padding: 20px 0px;
    color: #ffffff;
    width: 170px;
    transition: all 0.6s ease-in;
}

.MenuOptions li:hover {
    color: #0ac0ab;
}

.children {
    background: #35536C;
    border-radius: 0px 0px 4px 4px;
    display: none;
    list-style: none;
    margin-top: 20px;
    position: absolute;
    left: 0px;
    width: 180px;
    z-index: 1;
}

.children a li {
    display: block;
    overflow: hidden;
    font-size: 0.900rem;
    font-weight: bold;
    margin-left: -40px;
    padding: 20px 20px;
    transition: all 0.8s ease-in-out;
    text-align: center;
    width: 180px;
}

.children li:hover {
    color: #0ac0ab;
}

.MenuOptions li:hover .children {
    display: block;
}

.buttonCloseSesion {
    background: #35536C;
    text-align: center;
    margin-left: 180px;
    width: 100%;
    height: 58px;
    text-align: center;
}

.buttonCloseSesion button {
    background-color: #fefefe;
    border-radius: 5px;
    border: none;
    box-shadow: 5px 3px 1px #9b9b9b;
    color: #00122B;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    height: 40px;
    margin: 8px;
    text-transform: capitalize;
    width: 150px;
}

.buttonCloseSesion button:hover {
    color: #1625f4;
    position: relative;
    top: 0px;
}

.buttonCloseSesion button:active {
    box-shadow: 0px 0px 0px #ffffff;
    color: #1625f4;
    position: relative;
    top: 2px;
    left: 4px;
}

.btnWhatsapp {
    background-color: #09a903;
    border-radius: 100%;
    bottom: 30px;
    border: none;
    color: #ffff;
    cursor: pointer;
    position: fixed;
    padding: 10px;
    right: 20px;
    z-index: 1;
}

.socialLateral{
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
}

.socialItemLateral{
    background-color: #00122B;
    border-radius:0px 10px 10px 0px;
    margin-bottom: 10px;
    padding: 2px;
}

.fondoItemSocial span{
    font-size: 2.6rem;
    color: #ffffff;
}

@media only screen and (max-width: 2000px) and (min-width: 1600px) {

    /* Style for the navegation */
    .headerMenu {
        margin: 0px;
    }

    .MenuOptions {
        border: 0px solid #35536C;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        text-transform: capitalize;
        background-color: #35536C;
        width: 97.5%;
    }

    .MenuOptions li:hover .children {
        display: block;
    }

    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        height: 58px;
        text-align: center;
    }

    .btnWhatsapp {
        background-color: #09a903;
        border-radius: 100%;
        bottom: 40px;
        border: none;
        color: #ffff;
        cursor: pointer;
        position: fixed;
        padding: 10px;
        right: 60px;
        z-index: 1;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {

    /* Style for header */
    .sectionInfoHeader {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        margin: 40px;
    }

    .logoSection img {
        width: 300px;
        margin: 10px;
    }

    .sectionDirection p,
    .sectionDirection a {
        color: #000000;
        font-size: 0.800rem;
        font-weight: bold;
        text-decoration: none;
    }

    .sectionDirection a span {
        color: #E63946;
        font-size: 1rem;
        padding: 4px;
    }

    .icon-wristwatch {
        color: #00122B;
        font-size: 1rem;
        padding: 4px;
    }

    .sectionDirection a:hover {
        color: #09a903;
    }

    /* Style for the navegation */
    .icon-menu {
        background-color: #35536C;
        border-radius: 10px;
        top: 50px;
        border: none;
        color: #ffff;
        display: block;
        cursor: pointer;
        font-size: 1.4rem;
        position: fixed;
        padding: 20px;
        right: 60px;
        z-index: 1;
    }


    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        margin: 16px 0px 0px 0px;
        width: 100%;
        height: 58px;
        text-align: center;
    }

    .btnWhatsapp {
        background-color: #09a903;
        border-radius: 100%;
        bottom: 40px;
        border: none;
        color: #ffff;
        cursor: pointer;
        position: fixed;
        padding: 10px;
        right: 60px;
        z-index: 1;
    }

    .headerMenu {
        position: fixed;
        width: 700px;
        z-index: 1000;
        top: -20px;
        right: 0px;
    }

    .MenuOptions {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: none;
        background: #000000c4;
        transform: translateX(-500px);
        transition: transform .4s ease-in-out;
    }

    .menuResponsive {
        position: absolute;
        width: 60%;
        height: 120vh;
        background: #35536C;
        transform: translateX(-420px);
        transition: transform .8s ease-in-out;
        padding: 0px;
    }

    .menuResponsive .children {
        display: none;
        position: relative;
        width: 40%;
    }

    .children a{
        text-decoration: none;
    }
    
    .children a li {
        margin-left:100px;
        font-size: 0.800em;
        text-align: right;
    }

    .menuResponsive li:hover .children {
        display: block;
    }

    .optionNav {
        font-size: 1.5rem;
        padding: 20px;
    }

    nav ul {
        padding-top: 10px;
    }

    nav ul li {
        width: 86%;
        padding: 30px;
        background: #35536C;
        display: inline-block;
        color: #ffffff;
        text-align: right;
        font-weight: bold;
        font-size: 1.8rem;
        text-transform: capitalize;
    }

    nav ul li:hover {
        color: #1625f4;
    }

    .optionNav:hover {
        color: #000000;
    }

    nav ul li span {
        padding-right: 10px;
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 999px) and (min-width: 800px) {

    /* Style for the navegation */

    .icon-menu {
        background-color: #35536C;
        border-radius: 10px;
        top: 50px;
        border: none;
        color: #ffff;
        display: block;
        cursor: pointer;
        font-size: 1.4rem;
        position: fixed;
        padding: 20px;
        right: 60px;
        z-index: 1;
    }

    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        margin: 16px 0px 0px 0px;
        width: 100%;
        height: 58px;
        text-align: center;
    }

    .headerMenu {
        position: fixed;
        width: 700px;
        z-index: 1000;
        top: -20px;
        right: 0px;
    }

    .MenuOptions {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: none;
        background: #000000c4;
        transform: translateX(-500px);
        transition: transform .4s ease-in-out;
    }

    .menuResponsive {
        position: absolute;
        width: 60%;
        height: 120vh;
        background: #35536C;
        transform: translateX(-220px);
        transition: transform .8s ease-in-out;
        padding: 0px;
    }

    .menuResponsive .children {
        display: none;
        position: relative;
        width: 40%;
    }

    .children a{
        text-decoration: none;
    }
    
    .children a li {
        border-bottom: 1px solid #fff;
        font-size: 0.700em;
        margin-left:100px;
        text-align: right;
    }

    .menuResponsive li:hover .children {
        display: block;
    }

    .optionNav {
        font-size: 1.5rem;
        padding: 20px;
    }

    nav ul {
        padding-top: 10px;
    }

    nav ul li {
        width: 86%;
        padding: 30px;
        background: #35536C;
        display: inline-block;
        color: #ffffff;
        text-align: right;
        font-weight: bold;
        font-size: 1.8rem;
        text-transform: capitalize;
    }

    nav ul li:hover {
        color: #1625f4;
    }

    .optionNav:hover {
        color: #000000;
    }

    nav ul li span {
        padding-right: 10px;
        font-size: 1.1em;
    }

    .btnWhatsapp {
        background-color: #09a903;
        border-radius: 100%;
        bottom: 40px;
        border: none;
        color: #ffff;
        cursor: pointer;
        position: fixed;
        padding: 10px;
        right: 20px;
        z-index: 1;
    }
}

@media only screen and (max-width: 799px) and (min-width: 500px) {

    /* Style for header */
    .sectionInfoHeader {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        margin: 10px;
    }

    .logoSection img {
        width: 300px;
        margin: 10px;
    }

    .sectionDirection p,
    .sectionDirection a {
        color: #000000;
        font-size: 0.800rem;
        font-weight: bold;
        text-decoration: none;
    }

    .sectionDirection a span {
        color: #E63946;
        font-size: 1rem;
        padding: 4px;
    }

    .icon-wristwatch {
        color: #00122B;
        font-size: 1rem;
        padding: 4px;
    }

    .sectionDirection a:hover {
        color: #09a903;
    }

    /* Style for the navegation */
   
    .icon-menu {
        background-color: #35536C;
        border-radius: 10px;
        top: 50px;
        border: none;
        color: #ffff;
        display: block;
        cursor: pointer;
        font-size: 1.4rem;
        position: fixed;
        padding: 20px;
        right: 60px;
        z-index: 1;
    }

    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        margin: 16px 0px 0px 0px;
        width: 100%;
        height: 58px;
        text-align: center;
    }

    .headerMenu {
        position: fixed;
        width: 700px;
        z-index: 1000;
        top: -20px;
        right: 0px;
    }

    .MenuOptions {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: none;
        background: #000000c4;
        transform: translateX(-500px);
        transition: transform .4s ease-in-out;
    }

    .menuResponsive {
        position: absolute;
        width: 60%;
        height: 120vh;
        background: #35536C;
        transform: translateX(-40px);
        transition: transform .6s ease-in-out;
        padding: 0px;
    }

    .menuResponsive .children {
        display: none;
        position: relative;
        width: 40%;
    }

    .children a{
        text-decoration: none;
    }
    
    .children a li {
        margin-left:100px;
        font-size: 0.700em;
        text-align: right;
        border-bottom: 1px solid #fff;
    }

    .menuResponsive li:hover .children {
        display: block;
    }

    .optionNav {
        font-size: 1.5rem;
        padding: 20px;
    }

    nav ul {
        padding-top: 10px;
    }

    nav ul li {
        width: 86%;
        padding: 30px;
        background: #35536C;
        display: inline-block;
        color: #ffffff;
        text-align: right;
        font-weight: bold;
        font-size: 1.8rem;
        text-transform: capitalize;
    }

    nav ul li:hover {
        color: #1625f4;
    }

    .optionNav:hover {
        color: #000000;
    }

    nav ul li span {
        padding-right: 10px;
        font-size: 1.1em;
    }

    .btnWhatsapp {
        bottom: 80px;
        right: 40px;
        z-index: 1;
    }
}

@media only screen and (max-width: 499px) and (min-width: 250px) {

    /* Style for header */
    .sectionInfoHeader {
        display: flex;
        flex-direction: column;
        margin: 0px;
    }

    .logoSection img {
        width: 300px;
        margin: 10px;
    }

    .sectionDirection p,
    .sectionDirection a {
        color: #000000;
        font-size: 0.800rem;
        font-weight: bold;
        text-decoration: none;
    }

    .sectionDirection a span {
        color: #020202;
        font-size: 1rem;
        padding: 4px;
    }

    .icon-wristwatch {
        color: #00122B;
        font-size: 1rem;
        padding: 4px;
    }

    .sectionDirection a:hover {
        color: #09a903;
    }

    /* Style for the navegation */
    
    .icon-menu {
        background-color: #35536C;
        border-radius: 10px;
        top: 20px;
        border: none;
        color: #ffffff;
        display: block;
        cursor: pointer;
        font-size: 1.4rem;
        position: fixed;
        padding: 20px;
        right: 10px;
        z-index: 1000;
    }

    .icon-menu:active{ 
       background-color: rgb(18, 167, 194);
    }

    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        margin: 16px 0px 0px 0px;
        width: 100%;
        height: 58px;
        text-align: center;
    }

    .headerMenu {
        position: fixed;
        width: 700px;
        z-index: 1000;
        top: -20px;
        right: 0px;
    }

    .MenuOptions {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: none;
        background: #000000c4;
        transform: translateX(-500px);
        transition: transform .4s ease-in-out;
    }

    .menuResponsive {
        position: absolute;
        width: 60%;
        height: 120vh;
        background: #35536C;
        transform: translateX(140px);
        transition: transform .6s ease-in-out;
        padding: 0px;
    }

    .optionNav {
        font-size: 1.5rem;
        padding: 20px;
    }

    .menuResponsive {
        padding-top: 10px;
    }

    .menuResponsive li {
        width: 85%;
        padding: 30px;
        background: #35536C;
        display: inline-block;
        color: #ffffff;
        text-align: right;
        font-weight: bold;
        font-size: 1.6rem;
        text-transform: capitalize;
    }

    .menuResponsive li:hover {
        color: #2b75ff;
    }

    .optionNav:hover {
        color: #000000;
    }

    .menuResponsive li span {
        padding-right: 10px;
        font-size: 1.1em;
    }

    .menuResponsive .children {
        display: none;
        position: relative;
        width: 50%;
    }
    
    .children a{
        text-decoration: none;
    }
    
    .children a li {
        border-bottom: 1px solid #fff;
        margin-left:120px;
        font-size: 0.600em;
        text-align: right;
    }

    .menuResponsive li:hover .children {
        display: block;
    }

    .btnWhatsapp {
        bottom: 30px;
        right: 20px;
        z-index: 1;
    }

    .buttonCloseSesion {
        background: #35536C;
        text-align: center;
        margin-left: 220px;
        width: 10%;
        height: 58px;
        text-align: center;
    }

}