.titleGlossary{
    background-color: #00122B;
    color: #ffffff;
    font-size: 1.8rem;
    padding:20px 140px;
}

.glossarySectionBtns{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px;
}

.glossarySectionBtns button{
    background-color: #00122B;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 20px;
    padding: 16px 20px;
    text-transform: capitalize;
    transition: all 0.6s ease-in;
}

.glossarySectionBtns button:hover{
    background-color: #0846e3;
}

.DefinitionConcepts{
    background-color: #e6e6e6;
    margin: 20px;
    padding: 10px;
}

.DefinitionConcepts h4{
    font-size: 1.6rem;
    padding-left: 30px;
}

.DefinitionConcepts p{
    font-size: 1.2rem;
    margin: 20px;
}

.DefinitionConcepts p span{
    color: #0846e3;
    font-size: 1.8rem;
    padding: 0px 40px;
}

/* style for the FAQS */

.titleFaq {
    color: #00122B;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
}

.titleFaqhr {
    background-color: #0846e3;
    border: none;
    height: 3px;
    width: 700px;
}

.sectionFaq{
    background-color: #00122B;
    border-radius: 3px;
    cursor: pointer;
    margin: 20px 80px;
    padding: 6px 40px;
    width: 82%;
}

.sectionFaq h3{
    color: #ffffff;
    font-size: 1.3rem;
}

.infoFAQ{
    display: block;
    color: #dedddd;
    font-size: 1.1rem;
}

.NoShow {
    display: none;
}

.openFAQ{
    position: absolute;
    right: 140px;
    transform: rotate(90deg);
}

.openFAQ2{
    position: absolute;
    right: 140px;
    transform: rotate(0deg);
    transition: transform 0.6s ease-in-out;
}


@media only screen and (max-width: 999px) and (min-width: 768px) {

    .titleGlossary{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.8rem;
        padding:20px 140px;
    }
    
    .glossarySectionBtns{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 10px;
    }
    
    .glossarySectionBtns button{
        background-color: #00122B;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        margin: 10px;
        padding: 16px 10px;
        text-transform: capitalize;
        transition: all 0.6s ease-in;
    }
    
    .glossarySectionBtns button:hover{
        background-color: #0846e3;
    }
    
    .DefinitionConcepts{
        background-color: #e6e6e6;
        margin: 20px;
        padding: 10px;
    }
    
    .DefinitionConcepts h4{
        font-size: 1.6rem;
        padding-left: 30px;
    }
    
    .DefinitionConcepts p{
        font-size: 1.2rem;
        margin: 20px;
    }
    
    .DefinitionConcepts p span{
        color: #0846e3;
        font-size: 1.8rem;
        padding: 0px 40px;
    }

    .titleFaq {
        color: #00122B;
        font-size: 1.8rem;
        text-align: center;
        text-transform: uppercase;
    }
    
    .titleFaqhr {
        background-color: #0846e3;
        border: none;
        height: 3px;
        width: 300px;
    }
    
    .sectionFaq{
        background-color: #00122B;
        border-radius: 3px;
        cursor: pointer;
        margin: 20px 40px;
        padding: 6px 20px;
        width: 82%;
    }
    
    .sectionFaq h3{
        color: #ffffff;
        font-size: 1.4rem;
    }
    
    .infoFAQ{
        display: block;
        color: #dedddd;
        font-size: 1.2rem;
    }
    
    .NoShow {
        display: none;
    }
    
    .openFAQ{
        position: absolute;
        right: 60px;
        transform: rotate(90deg);
    }
    
    .openFAQ2{
        position: absolute;
        right: 20px;
        transform: rotate(0deg);
        transition: transform 0.6s ease-in-out;
    }
    
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .titleGlossary{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.8rem;
        padding:20px 140px;
    }
    
    .glossarySectionBtns{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 10px;
    }
    
    .glossarySectionBtns button{
        background-color: #00122B;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        width: 140px;
        padding: 20px;
        text-transform: capitalize;
        transition: all 0.6s ease-in;
    }
    
    .glossarySectionBtns button:hover{
        background-color: #0846e3;
    }
    
    .DefinitionConcepts{
        background-color: #e6e6e6;
        margin: 20px;
        padding: 10px;
    }
    
    .DefinitionConcepts h4{
        font-size: 1.6rem;
        padding-left: 30px;
    }
    
    .DefinitionConcepts p{
        font-size: 1.2rem;
        margin: 20px;
    }
    
    .DefinitionConcepts p span{
        color: #0846e3;
        font-size: 1.8rem;
        padding: 0px 40px;
    }
    
    /* style for the FAQS */

.titleFaq {
    color: #00122B;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
}

.titleFaqhr {
    background-color: #0846e3;
    border: none;
    height: 3px;
    width: 300px;
}

.sectionFaq{
    background-color: #00122B;
    border-radius: 3px;
    cursor: pointer;
    margin: 20px 20px;
    padding: 6px 20px;
    width: 82%;
}

.sectionFaq h3{
    color: #ffffff;
    font-size: 1.2rem;
}

.infoFAQ{
    display: block;
    color: #dedddd;
    font-size: 1rem;
}

.NoShow {
    display: none;
}

.openFAQ{
    position: absolute;
    right: 40px;
    transform: rotate(90deg);
}

.openFAQ2{
    position: absolute;
    right: 20px;
    transform: rotate(0deg);
    transition: transform 0.6s ease-in-out;
}

}