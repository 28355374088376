.titleAboutUs {
    color: #35536C;
    font-size: 2.3rem;
    text-align: center;
    text-transform: uppercase;
}

.lineSpace {
    width: 400px;
    height: 3px;
    background-color: #35536C;
    border: none;
}

.sectionInformativeLegalbf {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 80px 0px;
}

.sectionInformativeLegalbf p {
    color: #000000;
    font-size: 1.2rem;
    width: 600px;
    padding-left: 80px;
    line-height: 38px;
    animation: animationAboutUs1 1s ease-in-out;
    text-align: justify;
}

.sectionInformativeLegalbf p span {
    color: #35536C;
    font-size: 1.7rem;
}

.sectionInformativeLegalbf img {
    box-shadow: 14px 14px 0px #35536C;
    width: 500px;
}

.btnContactAbout{
    position: relative;
    left: 25%;
    bottom: 120px;
    background-color: #ffffff;
    border: 2px solid #35536C;
    border-radius: 3px;
    color: #35536C;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 16px 30px;
}

.btnContactAbout:hover{
    background-color: #35536C;
    border: 2px solid transparent;
    color: #ffffff;
}

/* Continer cards for mision and vision  */

.container-front {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
}

.cards {
    position: relative;
    width: 530px;
    margin: 60px;
    border-radius: 15px;
    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    cursor: pointer;
}

.cards img {
    z-index: 1px;
    width: 420px;
    height: 30vh;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    bottom: -1px;
    left: 60px;
}

.infoCard {
    position: absolute;
    width: 400px;
    bottom: 2px;
    left: 60px;
    height: 180px;
    padding: 0px 10px;
    background: #000000b3;
    transition: scale(0.9);
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 2px 4px 20px #000000;
    overflow: hidden;
    transition: all .4s;
}

.infoCard h3 {
    font-size: 1.8rem;
    padding: 5px;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
}

.infoCard p {
    font-size: 1em;
    padding-top: 90px;
    color: #ffffff;
    text-align: center;
}

.cards:hover .infoCard {
    height: 300px;
}

/* Styles for the cards our team */

.sectionTeamLegalbf {
    background-color: #35536C;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around
}

.targetTeam {
    background-color: #ffffff;
    border-radius: 10px;
    margin: 20px 40px;
    width: 400px;
}

.workerImage {
    padding: 20px;
    text-align: center;
}

.workerImage img {
    border: 4px solid #35536C;
    border-radius: 50%;
    width: 150px;
}

.positionWorker{
    color: #35536C;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.workerImage hr {
    height: 2px;
    background-color: #35536C
}

.nameWorker{
    font-size: 1.4rem;
    text-align: center;
}

.btnAboutMe{
    text-align: center;
    margin-bottom: 40px;
}

.btnAboutMe button{
    background-color: #35536C;
    border: 2px solid #35536C;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px;
    transition: background-color 0.6s ease-in-out;
}

.btnAboutMe button:hover{
    background-color: transparent;
    border: 2px solid #35536C;
    color: #35536C;
}

.icon-linkedin-squared {
    font-size:2rem;
    color: #1911f5;
}

.icon-mail-alt {
    font-size:2rem;
    color: #c60e0e;
}

.icon-whatsapp {
    font-size:2rem;
    color: #12ba15;
}

.icon-instagram{
    font-size:2rem;
    color: #d55121; 
    color: -moz-linear-gradient(45deg, #e13a2e 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    color: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    color: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.icon-twitter{
    font-size:2rem;
    color: #00b5fc;
}

/* modal workeds */
.icon-cancel-circled{
    border: none;
    border-radius: 100%;
    color: #f50000;
    cursor: pointer;
    font-size: 2.2rem;
    position: relative;
    right: 450px;
    text-align: center;
}

.icon-cancel-circled:hover{
    color: #f50000;
}

.backgroundModal {
    position: absolute;
    top: 0px;
    border: none;
    height: 100vh;
    width: 100%;
    background-color: #000000b6;
    cursor: default;
    overflow-y: scroll;
    height: 100%;
    left: 0;
    max-height: 100vh;
    position: fixed;
    text-decoration: none;
    top: 0;
    transition: all .7s;
    width: 100%;
    -webkit-transition: all .7s;
    z-index: 1;
}

.modalTeam{
    background-color: #ffffff;
    box-shadow: 0 0 10px #000000;
    display: grid;
    grid-template-columns: 40% 60%;
    height: 500px;
    position:fixed;
    width:950px;
    bottom: 40px;
    left: 200px;
    z-index: 1;
    animation: showModal 0.8s ease-in-out;
}

.modalTeam img{
    width:450px;
    height: 500px;
    box-shadow: 3px 0px 6px #010e20fe;
}

.infoModal{
    width:500px;
    padding: 20px 80px;
}

.infoModal h4{
    background-color: #35536C;
    bottom: 30px;
    color: #ffffff;
    font-size: 1.6rem;
    position: relative;
    right: 80px;
    padding:10px;
    text-align: center;
}

.infoModal h3{
    color: #35536C;
    font-size: 1.4rem;
    text-align: center;
}

.infoModal p{
    color: #000000;
    font-size: 0.800rem;
    padding: 20px 40px;
    text-align: justify;
}

.emailModal{
    color: #35536C;
    cursor: pointer;
    font-weight: bold;
    margin: 0px 160px;
    text-decoration: none;
    text-align: center;
}

.emailModal:hover{
    color: #ea0a0a;
}

.contactInfo{
    text-align: center;
    padding: 30px;
}

.contactInfo a{
    padding: 20px;
    cursor: pointer;
}


/* Animaciones */

@keyframes showModal {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes animationAboutUs1 {
    0%{
        opacity: 0;
        transform: translateX(-200px);
    }

    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}


@media only screen and (max-width: 2000px) and (min-width: 1600px) {
    .titleAboutUs {
        font-size: 2.8rem;
    }
    
    .lineSpace {
        width: 600px;
        height: 3px;
    }
    
    .sectionInformativeLegalbf {
        display: grid;
        grid-template-columns: 60% 30%;
        margin: 80px 0px;
    }
    
    .sectionInformativeLegalbf p {
        font-size: 2rem;
        width: 800px;
        padding-left: 80px;
        line-height: 48px;
    }
    
    .sectionInformativeLegalbf p span {
        font-size: 2.2rem;
    }
    
    .sectionInformativeLegalbf img {
        box-shadow: 14px 14px 0px #35536C;
        width: 600px;
    }
    
    .btnContactAbout{
        position: relative;
        font-size: 1.2rem;
    }
    
    /* Continer cards for mision and vision  */
    
    .container-front {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px;
    }
    
    .cards {
        position: relative;
        width: 530px;
        margin: 60px;
        cursor: pointer;
    }
    
    .cards img {
        z-index: 1px;
        width: 600px;
        height: 30vh;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        bottom: -1px;
        left: -60px;
    }
    
    .infoCard {
        position: absolute;
        width: 580px;
        bottom: 2px;
        left: -60px;
        height: 240px;
        padding: 0px 10px;
        background: #000000b3;
        transition: scale(0.9);
        color: #fff;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 2px 4px 20px #000000;
        overflow: hidden;
        transition: all .4s;
    }
    
    .infoCard h3 {
        font-size: 2rem;
        padding: 5px;
    }
    
    .infoCard p {
        font-size: 1.6em;
        padding-top: 130px;
    }

    .cards:hover .infoCard {
        height: 350px;
    }
    
    /* Styles for the cards our team */

    .icon-cancel-circled{
        border: none;
        border-radius: 100%;
        color: #e70c0c;
        cursor: pointer;
        font-size: 2.2rem;
        position: relative;
        right: 500px;
        bottom: 10px;
        text-align: center;
    }
    
    .sectionTeamLegalbf {
        background-color: #35536C;
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }
    
    .targetTeam {
        background-color: #ffffff;
        border-radius: 10px;
        margin: 20px 40px;
        width: 400px;
    }
    
    .workerImage {
        padding: 40px;
        text-align: center;
    }
    
    .workerImage img {
        border: 4px solid #35536C;
        border-radius: 50%;
        width: 150px;
    }
    
    .positionWorker{
        color: #35536C;
        font-size: 1.6rem;
    }
    
    .workerImage hr {
        height: 2px;
        background-color: #35536C
    }
    
    .nameWorker{
        font-size: 1.4rem;
        text-align: center;
    }

    .modalTeam{
        background-color: #ffffff;
        box-shadow: 0 0 10px #000000;
        display: grid;
        grid-template-columns: 40% 60%;
        height: 700px;
        position:fixed;
        width:1200px;
        top: 15%;
        left: 15%;
        z-index: 1;
        animation: showModal 0.8s ease-in-out;
    }
    
    .modalTeam img{
        width:450px;
        height: 700px;
        box-shadow: 3px 0px 6px #010e20fe;
    }
    
    .infoModal{
        width:500px;
        padding: 20px 80px;
    }
    
    .infoModal h4{
        background-color: #35536C;
        bottom: 30px;
        color: #ffffff;
        font-size: 1.6rem;
        position: relative;
        right: 80px;
        padding:10px;
        text-align: center;
    }
    
    .infoModal h3{
        color: #35536C;
        font-size: 1.4rem;
        text-align: center;
    }
    
    .infoModal p{
        color: #000000;
        font-size: 1.2rem;
        padding: 20px 20px;
        text-align: justify;
    }
    
    .emailModal{
        color: #35536C;
        cursor: pointer;
        font-weight: bold;
        margin: 0px 160px;
        text-decoration: none;
        text-align: center;
    }
    
    .emailModal:hover{
        color: #ea0a0a;
    }
    
    .contactInfo{
        text-align: center;
        padding: 30px;
    }
    
    .contactInfo a{
        padding: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 999px) and (min-width: 768px) {
    .titleAboutUs {
        font-size: 2.8rem;
    }
    
    .lineSpace {
        width: 600px;
        height: 3px;
    }

    .containerInfoAbout{
        display: grid;
        grid-template-columns: 100%;
    }
    
    .sectionInformativeLegalbf {
        display: grid;
        grid-template-columns: 100%;
        margin: 80px 0px;
    }
    
    .sectionInformativeLegalbf p {
        font-size: 1.6rem;
        width: 80%;
        line-height: 48px;
    }
    
    .sectionInformativeLegalbf p span {
        font-size: 2.2rem;
    }
    
    .sectionInformativeLegalbf img {
        box-shadow: 14px 14px 0px #35536C;
        margin: 20px 80px;
        width: 600px;
    }
    
    .btnContactAbout{
        font-size: 1.2rem;
        padding: 20px 40px;
        width: 400px;
    }
    
    /* Continer cards for mision and vision  */
    
    .container-front {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px;
    }
    
    .cards {
        position: relative;
        width: 100%;
        margin: 80px;
        cursor: pointer;
    }
    
    .cards img {
        z-index: 1px;
        width: 700px;
        height: 30vh;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        bottom: -1px;
        left: -60px;
    }
    
    .infoCard {
        position: absolute;
        width: 680px;
        bottom: 2px;
        left: -60px;
        height: 230px;
        padding: 0px 10px;
        background: #000000b3;
        transition: scale(0.9);
        color: #fff;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 2px 4px 20px #000000;
        overflow: hidden;
        transition: all .4s;
    }
    
    .infoCard h3 {
        font-size: 2rem;
        padding: 5px;
    }
    
    .infoCard p {
        font-size: 1.6em;
        padding-top: 130px;
    }

    .cards:hover .infoCard {
        height: 350px;
    }
    
    /* Styles for the cards our team */
    
    .sectionTeamLegalbf {
        background-color: #35536C;
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }
    
    .targetTeam {
        background-color: #ffffff;
        border-radius: 10px;
        margin: 20px 40px;
        width: 400px;
    }
    
    .workerImage {
        padding: 40px;
        text-align: center;
    }
    
    .workerImage img {
        border: 4px solid #35536C;
        border-radius: 50%;
        width: 150px;
    }
    
    .positionWorker{
        color: #35536C;
        font-size: 1.6rem;
    }
    
    .workerImage hr {
        height: 2px;
        background-color: #35536C
    }
    
    .nameWorker{
        font-size: 1.4rem;
        text-align: center;
    }

    .icon-cancel-circled{
        border: none;
        border-radius: 100%;
        color: #000000;
        cursor: pointer;
        font-size: 2.2rem;
        position: relative;
        right: 320px;
        bottom: 10px;
        text-align: center;
    }

    .modalTeam{
        background-color: #ffffff;
        box-shadow: 0 0 10px #000000;
        display: grid;
        grid-template-columns: 40% 60%;
        height: 500px;
        position:fixed;
        width: 96%;
        
        top: 25%;
        left: 20px;
        z-index: 1;
        animation: showModal 0.8s ease-in-out;
    }
    
    .modalTeam img{
        width:300px;
        height: 500px;
        box-shadow: 3px 0px 6px #010e20fe;
    }
    
    .infoModal{
        width:400px;
        padding: 20px 20px;
    }
    
    .infoModal h4{
        background-color: #35536C;
        bottom: 30px;
        color: #ffffff;
        font-size: 1.1rem;
        position: relative;
        right: 20px;
        padding:10px;
        text-align: center;
    }
    
    .infoModal h3{
        color: #35536C;
        font-size: 1rem;
        text-align: center;
    }
    
    .infoModal p{
        color: #000000;
        font-size: 0.850rem;
        padding: 20px 20px;
        text-align: justify;
    }
    
    .emailModal{
        color: #35536C;
        cursor: pointer;
        font-weight: bold;
        margin: 0px 50px;
        text-decoration: none;
        text-align: center;
    }
    
    .emailModal:hover{
        color: #ea0a0a;
    }
    
    .contactInfo{
        text-align: center;
        padding: 30px;
    }
    
    .contactInfo a{
        padding: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .titleAboutUs {
        font-size: 2rem;
    }
    
    .lineSpace {
        width: 200px;
        height: 3px;
    }
    
    .sectionInformativeLegalbf {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 20px 0px;
    }
    
    .sectionInformativeLegalbf p {
        font-size: 1.2rem;
        width: 260px;
        line-height: 38px;
    }
    
    .sectionInformativeLegalbf p span {
        font-size: 1.6rem;
    }
    
    .sectionInformativeLegalbf img {
        box-shadow: 14px 14px 0px #35536C;
        width: 300px;
        margin-left: 40px;
    }
    
    .btnContactAbout{
        position: relative;
        left: 60px;
        top: 30px;
        font-size: 1.2rem;
        padding: 16px 60px;
    }
    
    /* Continer cards for mision and vision  */
    
    .container-front {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 40px;
    }
    
    .cards {
        position: relative;
        width: 100%;
        margin: 40px 20px;
        cursor: pointer;
    }
    
    .cards img {
        z-index: 1px;
        width: 300px;
        height: 27vh;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        bottom: -1px;
        left: 0px;
    }
    
    .infoCard {
        position: absolute;
        width: 280px;
        bottom: 2px;
        left:   0px;
        height: 230px;
        padding: 0px 10px;
        background: #000000b3;
        transition: scale(0.9);
        color: #fff;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 2px 4px 20px #000000;
        overflow: hidden;
        transition: all .4s;
    }
    
    .infoCard h3 {
        font-size: 2rem;
        padding: 5px;
    }
    
    .infoCard p {
        font-size: 0.950em;
        padding-top: 120px;
    }

    .cards:hover .infoCard {
        height: 350px;
    }
    
    /* Styles for the cards our team */
    
    .sectionTeamLegalbf {
        background-color: #35536C;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around
    }
    
    .targetTeam {
        background-color: #ffffff;
        border-radius: 10px;
        margin: 20px 0px;
        width: 320px;
    }
    
    .workerImage {
        padding: 40px;
        text-align: center;
    }
    
    .workerImage img {
        border: 4px solid #35536C;
        border-radius: 50%;
        width: 150px;
    }
    
    .positionWorker{
        color: #35536C;
        font-size: 1.6rem;
    }
    
    .workerImage hr {
        height: 2px;
        background-color: #35536C
    }
    
    .nameWorker{
        font-size: 1.4rem;
        text-align: center;
    }

    .icon-cancel-circled{
        border: none;
        border-radius: 100%;
        color: #000000;
        cursor: pointer;
        font-size: 2.2rem;
        position: relative;
        right: 20px;
        bottom: 10px;
        text-align: center;
    }

    .modalTeam{
        background-color: #ffffff;
        box-shadow: 0 0 10px #000000;
        display: grid;
        grid-template-columns: 40% 60%;
        height: 640px;
        position:fixed;
        width:360px;
        bottom: 50px;
        left: 20px;
        z-index: 1;
        animation: showModal 0.8s ease-in-out;
    }
    
    .modalTeam img{
        width:200px;
        display: none;
        height: 500px;
        box-shadow: 3px 0px 6px #010e20fe;
    }
    
    .infoModal{
        width:320px;
        padding: 20px 20px;
    }
    
    .infoModal h4{
        background-color: #35536C;
        bottom: 30px;
        color: #ffffff;
        font-size: 1.1rem;
        position: relative;
        right: 20px;
        padding:10px;
        text-align: center;
    }
    
    .infoModal h3{
        color: #35536C;
        font-size: 1rem;
        text-align: center;
    }
    
    .infoModal p{
        color: #000000;
        font-size: 0.850rem;
        padding: 20px 40px;
        text-align: justify;
    }
    
    .emailModal{
        color: #35536C;
        cursor: pointer;
        font-weight: bold;
        margin: 0px 50px;
        text-decoration: none;
        text-align: center;
    }
    
    .emailModal:hover{
        color: #ea0a0a;
    }
    
    .contactInfo{
        text-align: center;
        padding: 30px;
    }
    
    .contactInfo a{
        padding: 20px;
        cursor: pointer;
    }
    
}