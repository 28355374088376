body{ 
    background-color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
}

/**editar la barra lateral*/
body::-webkit-scrollbar {
    background: #ffffff;
    width: 14px;
}

body::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
}

body::-webkit-scrollbar-thumb {
    background-color: #00122B;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}