.containerSlider {
  color: #ffffff;
  height: 90vh;
  width: 100%;
  margin: -20px 0px;
}

/* .btn-back {
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 80px;
  border-radius: 10px;
  text-transform: capitalize;
}

.btn-next {
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  right: 80px;
  border-radius: 10px;
  text-transform: capitalize;
} */

.sliderSection {
  box-shadow: inset 0 0 0 1000px #00000089;
  width: 1100px;
  margin: 0px 150px;
  border-radius: 10px;
}

.slider {
  box-shadow: 0px 20px 30px #000000da;
  object-fit: contain;
  border-radius: 10px;
  height: 550px;
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.75s ease-in-out;
}

.active {
  opacity: 0;
}

.slider img {
  height: 550px;
  position: absolute;
  border-radius: 10px;
  width: 1100px;
  z-index: -1;
}

.infoSlider {
  text-align: center;
}

.infoSlider p {
  font-size: 2rem;
  position: relative;
  top: 120px;
  left: 250px;
  text-align: center;
  width: 600px;
}

.infoSlider h2 {
  font-size: 4rem;
  position: relative;
  top: 100px;
  text-align: center;
}

.infoSlider button {
  background-color: #00122B;
  border: none;
  color: #fff;
  font-size: 1rem;
  padding: 10px 0px;
  position: relative;
  top: 50px;
  font-weight: bold;
  border-radius: 4px;
}


/* animation for the bottom, explain */
.wrapper {
  position: relative;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper button {
  width: 300px;
  height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  position: relative;
  transition: all 3s;
}

.wrapper button span {
  position: relative;
  z-index: 2;
}

.wrapper button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ffffff;
  transition: all .40s;
}

.wrapper button:hover {
  color: #00122B;
}

.wrapper button:hover:after {
  width: 100%;
}

/* informative section at the beginning, it is a small review of the company  */

.containerReview {
  background-color: #00122B;
  padding: 40px 80px;
}

.headerReview {
  display: flex;
}

.headerReview img {
  position: relative;
  top: 20px;
  width: 350px;
  height: 200px;
}

.headerReview h1 {
  font-size: 3.4rem;
  color: #ffffff;
  margin-left: 40px;
} 

.headerReview p {
  font-size: 1.6rem;
  color: #ffffff;
  margin: -40px 40px;
}

.paragrapReview {
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 30px;
  margin-top: 80px;
  text-align: justify;
}

.sectionBtnAboutMore {
  margin: 60px 0px 0px 0px;
  padding: 0px;
}

.sectionBtnAboutMore button {
  background-color: transparent;
  border: 3px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;
  height: 50px;
  text-transform: capitalize;
  transition: all 0.8s ease-in;
  width: 180px;
}

.sectionBtnAboutMore button:hover {
  background-color: #0846e3;
  border: 2px solid #0846e3;
}

/* Container that shows a little information about legalbf services */

.titleServices{
  font-size: 2.3rem;
  text-align: center;
  color: #35536C;
  text-transform:uppercase;
}

.containerTargesServices{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 80px;
    animation: showTargetsServices 1s ease-in;
}

.targetServices {
  background-color: #00122B;
  border-radius: 6px;
  box-shadow: 10px 10px 10px #898989;
  color: #ffffff;
  cursor: pointer;
  height: 200px;
  width: 500px;
  transition: transform 0.8s ease-in;
}

.targetServices:hover {
  transform: translateY(-40px);
  background-color: #35536C;
}

.targetServices  h3 {
  padding-top: 40px;
  text-decoration: none;
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
}

.targetServices p {
  padding: 30px 60px;
  width: 400px;
}

.SectionContactBtn{
  background-color: #00122B;
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 10px;
} 

.SectionContactBtn p{
  font-size: 1.6rem;
  text-align: center;
  color: #ffffff;
}

.SectionContactBtn button{
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  color: #35536C;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
  height: 60px;
  width: 150px;
}

.SectionContactBtn button:hover{
  background-color: #35536C;
  color: #ffffff;
}

.articleServicesItemes{
  display: flex;
  justify-content: space-around;
}

.itemsServices{
  margin: 100px 0px 0px 0px;
}

.itemsServices img{
  left: 80px;
  position: relative;
  width: 140px;
}

.itemsServices p{
  font-size: 1.2rem;
  text-align: center;
  width: 300px;
}


/* Animations home */

@keyframes showTargetsServices {
    0%{
      transform: translateX( -400px);
    }

    100%{
      transform: translateX(0px);
    }
}


@media only screen and (max-width: 2000px) and (min-width: 1600px) {
  .containerSlider {
    height: 80vh;
    width: 100%;
    margin: 20px 0px;
  }

  .sliderSection {
    width: 90%;
    margin: 0px 100px;
  }
  
  .slider {
    height: 600px;
    max-width: 100%;
    transition: opacity 0.75s ease-in-out;
  }

  .slider img {
    height: 600px;
    width: 90%;
    z-index: -1;
  }

  .infoSlider p {
    font-size: 2.4rem;
    top: 120px;
    left: 350px;
    text-align: center;
    width: 1000px;
  }
  
  .infoSlider h2 {
    font-size: 4.8rem;
    top: 100px;
  }

  .headerReview h1 {
    font-size: 3.8rem;
    margin-left: 40px;
  }
  
  .headerReview p {
    font-size: 2.4rem;
  }

  .titleServices{
    font-size: 2.5rem;
  }
  
  .containerTargesServices{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 60px 100px;
  }
  
  .targetServices {
    height: 300px;
    width: 600px;
  }
  
  .targetServices:hover {
    transform: translateY(-30px);
  }
  
  .targetServices  h3 {
    padding-top: 40px;
    text-decoration: none;
    font-size: 1.6rem;
    text-align: center;
    text-transform: uppercase;
  }
  
  .targetServices p {
    font-size: 1.3rem;
    padding: 40px 60px;
    width: 400px;
  }
  
  .SectionContactBtn{
    background-color: #00122B;
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 10px;
  } 
  
  .SectionContactBtn p{
    font-size: 1.6rem;
    text-align: center;
    color: #ffffff;
  }
  
  .SectionContactBtn button{
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    color: #35536C;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 10px;
    height: 60px;
    width: 150px;
  }
  
  .SectionContactBtn button:hover{
    background-color: #35536C;
    color: #ffffff;
  }
  
  .articleServicesItemes{
    display: flex;
    justify-content: space-around;
  }
  
  .itemsServices{
    margin: 40px 0px 100px 0px;
  }
  
  .itemsServices img{
    left: 60px;
    position: relative;
    width: 140px;
  }
  
  .itemsServices p{
    font-size: 1rem;
    text-align: justify;
    width: 280px;
  }

}

@media only screen and (max-width: 999px) and (min-width: 768px) {
  .containerSlider {
    height: 50vh;
    width: 100%;
    margin: 80px 0px;
  }

  .sliderSection {
    width: 100%;
    margin: 0px 0px;
  }
  
  .slider {
    height: 540px;
    max-width: 100%;
    transition: opacity 0.75s ease-in-out;
  }

  .slider img {
    height: 540px;
    width: 100%;
    z-index: -1;
  }

  .infoSlider p {
    font-size: 1.2rem;
    top: 120px;
    left: 0px;
    text-align: center;
    margin: 20px auto;
    width: 600px;
  }
  
  .infoSlider h2 {
    font-size: 2.4rem;
    top: 140px;
  }

  .infoSlider button {
    font-size: 1.2rem;
    padding: 10px 30px;
    top: 100px;
  }

  .containerReview {
    background-color: #00122B;
    padding: 40px 80px;
    width: 80%;
  }

  .headerReview h1 {
    font-size: 3.8rem;
    margin-left: 40px;
  }
  
  .headerReview p {
    font-size: 1.4rem;
  }

  .titleServices{
    font-size: 2.5rem;
  }
  
  .containerTargesServices{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 80px 40px;
  }
  
  .targetServices {
    height: 300px;
    width: 250px;
  }
  
  .targetServices:hover {
    transform: translateY(-30px);
  }
  
  .targetServices  h3 {
    padding-top: 40px;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
  }
  
  .targetServices p {
    font-size: 0.900rem;
    padding: 20px 30px;
    width: 200px;
  }
  
  .SectionContactBtn{
    background-color: #00122B;
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 10px;
  } 
  
  .SectionContactBtn p{
    font-size: 1.6rem;
    text-align: center;
    color: #ffffff;
  }
  
  .SectionContactBtn button{
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    color: #35536C;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 20px;
    height: 60px;
    width: 140px;
  }
  
  .SectionContactBtn button:hover{
    background-color: #35536C;
    color: #ffffff;
  }
  
  .articleServicesItemes{
    display: flex;
    justify-content: space-around;
  }
  
  .itemsServices{
    margin: 40px 0px 30px 0px;
  }
  
  .itemsServices img{
    left: 20px;
    position: relative;
    width: 100px;
  }
  
  .itemsServices p{
    font-size: 0.900rem;
    text-align: justify;
    width: 140px;
  }

}

@media only screen and (max-width: 767px) and (min-width: 250px) {
  .containerSlider {
    height: 70vh;
    width: 100%;
    margin: 40px 0px;
  }

  .sliderSection {
    width: 98%;
    margin: 0px 4px;
  }
  
  .slider {
    height: 540px;
    max-width: 100%;
    transition: opacity 0.75s ease-in-out;
  }

  .slider img {
    height: 540px;
    width: 98%;
    z-index: -1;
  }

  .infoSlider p {
    font-size: 1.2rem;
    top: 120px;
    left: 0px;
    text-align: center;
    width: 360px;
  }
  
  .infoSlider h2 {
    font-size: 2rem;
    top: 140px;
  }

  .infoSlider button {
    font-size: 1.2rem;
    padding: 10px 30px;
    top: 100px;
  }

  .containerReview {
    background-color: #00122B;
    padding: 20px 40px;
    width: 80%;
  }

  .headerReview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .headerReview h1 {
    font-size: 3.2rem;
    margin-left: 0px;
  }
  
  .headerReview p {
    font-size: 1.4rem;
    margin-left: 0px;
  }

  .sectionBtnAboutMore button {
    background-color: transparent;
    border: 3px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.2rem;
    height: 50px;
    text-transform: capitalize;
    transition: all 0.8s ease-in;
    width: 220px;
  }

  .titleServices{
    font-size: 2rem;
  }
  
  .containerTargesServices{
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 60px 30px;
  }

  .targetServices {
    height: 300px;
    width: 350px;
    margin-top: 60px;
  }
  
  .targetServices:hover {
    transform: translateY(-30px);
    background-color: #0846e3;
  }
  
  .targetServices  h3 {
    padding-top: 40px;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
  }
  
  .targetServices p {
    font-size: 1rem;
    padding: 20px 30px;
    width: 300px;
  }
  
  .SectionContactBtn{
    background-color: #00122B;
    display: grid;
    grid-template-columns: 70%;
    padding: 10px;
  } 
  
  .SectionContactBtn p{
    font-size: 1.6rem;
    color: #ffffff;
    width: 360px;
    text-align: center;
  }
  
  .SectionContactBtn button{
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    color: #35536C;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 40px;
    height: 60px;
    width: 300px;
  }
  
  .SectionContactBtn button:hover{
    background-color: #35536C;
    color: #ffffff;
  }
  
  .articleServicesItemes{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .itemsServices{
    margin: 40px 80px;
  }
  
  .itemsServices img{
    left: 50px;
    position: relative;
    width:140px;
  }
  
  .itemsServices p{
    font-size: 1.2rem;
    text-align: justify;
    width: 240px;
  }

}