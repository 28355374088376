.titlePurchaseSentences {
    border-radius: 0px 80px 80px 0px;
    display: inline-block;
    background-color: #00122B;
    color: #ffffff;
    font-size: 2rem;
    padding: 10px 80px;
    text-transform: uppercase;
}

.textInfoPurchase p {
    color: #000000;
    font-size: 1.2rem;
    padding: 20px;
    position: relative;
    width: 500px;
    text-align: justify;
}

.textInfoPurchase p span {
    color: #0a3bb8;
    font-size: 1.4rem;
    font-weight: bold;
}

.textInfoPurchase button {
    background-color: #00122B;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 20px;
    padding: 16px 30px;
}

.Info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 40px;
}

.Info img {
    padding: 20px;
    height: 350px;
    width: 500px;
    box-shadow: 4px 4px 0px #00122B;
}

.video {
    padding: 40px;
    text-align: center;
}

.video iframe {
    border: 8px solid #00122B;
    border-radius: 20px;
    height: 300px;
    width: 500px;
}

.containerInfoPurchase {
    background-color: #00122B;
    padding: 30px;
}

.containerInfoPurchase h3 {
    color: #ffffff;
    font-size: 1.6rem;
    text-align: center;
    text-transform: uppercase;
}

.containerInfoPurchase hr {
    background-color: #ffffff;
    border: none;
    height: 3px;
    width: 500px;
}

.ListBenefist {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
}

.targetsBenefits {
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    width: 600px;
}

.targetsBenefits p {
    color: #0b62ed;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
}

.targetsBenefits ul li {
    color: #000000;
    font-size: 1.2rem;
    list-style: none;
    padding: 12px 0px;
}

.icon-hammer {
    color: #6c3d0d;
    font-size: 1.4rem;
    padding: 10px;
}

/* step by step buying sentences */

.titleSteps {
    font-size: 1.8rem;
    margin: 70px;
    padding: 0px 200px;
    text-align: center;
    text-transform: uppercase;
}

.stepBuyingSentences {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0px;
}

.steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 6px 8px 2px #3c3c3cc6;
    border-radius: 20px;
}

.boxSteps {
    margin-left: 50px;
}

.numberSteps {
    background-color: #0b62ed;
    border-radius: 100%;
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    padding: 10px 10px;
    position: absolute;
    top: 1900px;
    animation: changeColor1 10s ease-in-out infinite;
}

.steps h4 {
    color: #1c2d59;
    font-size: 1.1rem;
    font-weight: bold;
    width: 260px;
}

.infoSteps {
    font-size: 0.800rem;
    line-height: 25px;
    width: 230px;
    text-align: justify;
}

/* Section allie's imags */

.sectionAllies {
    margin-top: 80px;
}

.sectionAllies hr {
    background-color: #0846e3;
    height: 4px;
    width: 600px;
}

.sectionImgAllies {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sectionImgAllies img {
    width: 180px;
    margin: 40px 00px;
}

/* Section for consulting */

.subtitleConsul {
    font-size: 2.2rem;
    font-weight: bold;
    margin: 40px;
    text-align: center;
}

.sectionInfoConsul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 40px;
}

.sectionInfoConsul p {
    font-size: 1.4rem;
    margin: 40px;
    text-align: justify;
}

.sectionInfoConsul p span {
    color: #0846e3;
}

.listServicesProvided {
    background-color: #00122B;
    border-radius: 40px;
    box-shadow: 20px 20px 0px #000000;
    color: #ffffff;
    font-size: 1.3rem;
    margin: 20px 300px;
    padding: 20px;
}

.listServicesProvided ul li {
    padding: 10px;
}

.sectionPartners {
    background-image: url('../../assets/images/socios.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 500px;
    margin: 120px 0px 80px 0px;
}

.sectionPartners p {
    color: #ffffff;
    font-size: 3.2rem;
    left: 200px;
    position: relative;
    top: 80px;
    text-align: center;
    width: 800px;
}

.btnLinkPartners {
    background-color: transparent;
    border: none;
    border: 4px solid #0846e3;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.3rem;
    left: 400px;
    padding: 14px;
    position: relative;
    top: 150px;
    transition: all 0.8s ease-in;
    width: 300px;
}

.btnLinkPartners:hover {
    background-color: #0846e3;
}

/* animation */

@keyframes changeColor1 {

    from {
        margin-left: 0%;
        margin-top: 2%;
    }

    30% {
        margin-left: 24%;
        margin-top: 2%;
    }

    60% {
        margin-left: 50%;
        margin-top: 2%;
    }

    90% {
        margin-left: 74%;
        margin-top: 2%;
        opacity: 1;
    }

    to {
        margin-left: 0%;
        opacity: 0;
    }
}

.containerGlossary{
    margin-top:80px;
}

.containerFaq{
    background-color: #cecece;
    margin-top:80px;
    padding: 10px;
}


@media only screen and (max-width: 999px) and (min-width: 768px) {
    /* step by step buying sentences */

    .titleSteps {
        font-size: 2.2rem;
        padding: 0px;
        text-align: center;
        margin: 40px;
    }

    @keyframes changeColor1 {
        from {
            margin-top: 220px;
        }

        30% {
            margin-top: 420px;
        }

        60% {
            margin-top: 620px;
        }

        90% {
            margin-top: 820px;
        }

        to {
            margin-top: 220px;
        }
    }

    /* Section allie's imags */

    .sectionAllies {
        margin-top: 120px;
    }

    .sectionAllies hr {
        background-color: #0846e3;
        height: 4px;
        width: 400px;
    }

    .sectionImgAllies {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 100px;
    }

    .sectionImgAllies img {
        width: 200px;
        margin: 20px 0px;
    }


    .textInfoPurchase p {
        color: #000000;
        font-size: 1.4rem;
        line-height: 40px;
        padding: 20px;
        position: relative;
        width: 500px;
    }

    .textInfoPurchase p span {
        color: #0a3bb8;
        font-size: 1.4rem;
        font-weight: bold;
    }

    .textInfoPurchase button {
        background-color: #00122B;
        border: none;
        border-radius: 3px;
        color: #ffffff;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        margin-left: 20px;
        padding: 16px 30px;
    }

    .Info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 40px;
        flex-wrap: wrap;
    }

    .Info img {
        padding: 20px;
        height: 400px;
        width: 400px;
    }

    .video {
        padding: 40px;
        text-align: center;
    }

    .video iframe {
        border: 8px solid #00122B;
        border-radius: 20px;
        height: 300px;
        width: 500px;
    }

    .containerInfoPurchase {
        background-color: #00122B;
        padding: 30px;
    }

    .containerInfoPurchase h3 {
        color: #ffffff;
        font-size: 1.6rem;
        text-align: center;
        text-transform: uppercase;
    }

    .containerInfoPurchase hr {
        background-color: #ffffff;
        border: none;
        height: 3px;
        width: 500px;
    }

    .ListBenefist {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px;
    }

    .targetsBenefits {
        background-color: #ffffff;
        margin: 10px;
        padding: 10px;
        width: 700px;
    }

    .targetsBenefits p {
        color: #0b62ed;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
    }

    .targetsBenefits ul li {
        color: #000000;
        font-size: 1rem;
        list-style: none;
        padding: 12px 0px;
    }

    .icon-hammer {
        color: #6c3d0d;
        font-size: 1.4rem;
        padding: 10px;
    }

    /* step by step buying sentences */

    .stepBuyingSentences {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 50px;
    }

    .steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 700px;
        margin-left: 60px;
    }

    .boxSteps {
        margin: 20px 10px;
    }

    .numberSteps {
        font-size: 1.4rem;
        padding: 10px 10px;
        position: absolute;
        top: 2300px;
        left: 20px;
        animation: changeColor1 10s ease-in-out infinite;
    }

    .steps h4 {
        font-size: 1.6rem;
        width: 400px;
    }

    .infoSteps {
        font-size: 1.2rem;
        width: 100%;
    }

    /* Section for consulting */

    .subtitleConsul {
        font-size: 2.2rem;
        font-weight: bold;
        margin: 20px;
        text-align: center;
    }

    .sectionInfoConsul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 20px;
    }

    .sectionInfoConsul p {
        font-size: 1.4rem;
        margin: 20px;
    }

    .sectionInfoConsul p span {
        color: #0846e3;
    }

    .listServicesProvided {
        background-color: #00122B;
        border-radius: 40px;
        box-shadow: 20px 20px 0px #000000;
        color: #ffffff;
        font-size: 1.3rem;
        margin: 20px 40px;
        padding: 20px;
    }

    .listServicesProvided ul li {
        padding: 10px;
    }

    .sectionPartners {
        background-image: url('../../assets/images/socios.webp');
        background-repeat: no-repeat;
        background-attachment: fixed;
        height: 500px;
        margin: 120px 0px 80px 0px;
    }

    .sectionPartners p {
        color: #ffffff;
        font-size: 1.8rem;
        left: 50px;
        position: relative;
        top: 80px;
        text-align: center;
        width: 500px;
    }

    .btnLinkPartners {
        background-color: transparent;
        border: none;
        border: 4px solid #0846e3;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.3rem;
        left: 160px;
        padding: 14px;
        position: relative;
        top: 150px;
        transition: all 0.8s ease-in;
        width: 200px;
    }

    .btnLinkPartners:hover {
        background-color: #0846e3;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .titlePurchaseSentences {
        border-radius: 0px 80px 80px 0px;
        display: inline-block;
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        padding: 10px 20px;
        text-transform: uppercase;
    }

    /* step by step buying sentences */

    .titleSteps {
        font-size: 2.2rem;
        padding: 0px;
        text-align: center;
        margin: 40px;
    }

    @keyframes changeColor1 {
        from {
            margin-top: 500px;
        }

        30% {
            margin-top: 800px;
        }

        60% {
            margin-top: 1100px;
        }

        90% {
            margin-top: 1400px;
        }

        to {
            margin-top: 500px;
        }
    }

    .textInfoPurchase p {
        color: #000000;
        font-size: 1rem;
        line-height: 40px;
        padding: 20px;
        position: relative;
        width: 300px;
    }

    .textInfoPurchase p span {
        color: #0a3bb8;
        font-size: 1.1rem;
        font-weight: bold;
    }

    .textInfoPurchase button {
        background-color: #00122B;
        border: none;
        border-radius: 3px;
        color: #ffffff;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        margin-left: 20px;
        padding: 16px 30px;
    }

    .Info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 40px;
        flex-wrap: wrap;
    }

    .Info img {
        padding: 20px;
        height: 400px;
        width: 400px;
        display: none;
    }

    .video {
        padding: 40px;
        text-align: center;
    }

    .video iframe {
        border: 8px solid #00122B;
        border-radius: 20px;
        height: 300px;
        width: 300px;
    }

    .containerInfoPurchase {
        background-color: #00122B;
        padding: 30px;
    }

    .containerInfoPurchase h3 {
        color: #ffffff;
        font-size: 1.6rem;
        text-align: center;
        text-transform: uppercase;
    }

    .containerInfoPurchase hr {
        background-color: #ffffff;
        border: none;
        height: 3px;
        width: 200px;
    }

    .ListBenefist {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 10px;
        flex-wrap: wrap;
    }

    .targetsBenefits {
        background-color: #ffffff;
        margin: 10px;
        padding: 10px;
        width: 800px;
    }

    .targetsBenefits p {
        color: #0b62ed;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
    }

    .targetsBenefits ul li {
        color: #000000;
        font-size: 0.900rem;
        list-style: none;
        padding: 12px 0px;
    }

    .icon-hammer {
        color: #6c3d0d;
        font-size: 1.rem;
        padding: 10px;
    }

    /* step by step buying sentences */

    .stepBuyingSentences {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 40px;
    }

    .steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 10px;
        width: 360px;
    }

    .boxSteps {
        margin-left: 40px;
    }

    .numberSteps {
        font-size: 1.2rem;
        padding: 10px 10px;
        position: absolute;
        top: 2300px;
        left: 20px;
        animation: changeColor1 10s ease-in-out infinite;
    }

    .steps h4 {
        font-size: 1.1rem;
        width: 320px;
    }

    .infoSteps {
        font-size: 0.900rem;
        width: 300px;
    }

    /* Section allie's imags */

    .sectionAllies {
        margin-top: 120px;
    }

    .sectionAllies hr {
        background-color: #0846e3;
        height: 4px;
        width: 200px;
    }

    .sectionImgAllies {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .sectionImgAllies img {
        width: 300px;
        margin: 40px 40px;
    }

    /* Section for consulting */

    .subtitleConsul {
        font-size: 2rem;
        font-weight: bold;
        margin: 40px;
        text-align: center;
    }

    .sectionInfoConsul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 20px;
    }

    .sectionInfoConsul p {
        font-size: 1.4rem;
        margin: 40px;
    }

    .sectionInfoConsul p span {
        color: #0846e3;
    }

    .listServicesProvided {
        background-color: #00122B;
        border-radius: 40px;
        box-shadow: 20px 20px 0px #000000;
        color: #ffffff;
        font-size: 1rem;
        margin: 10px 30px;
        padding: 10px;
    }

    .listServicesProvided ul li {
        padding: 10px;
    }

    .sectionPartners {
        background-image: none;
        background-image: url('../../assets/images/socios.webp');
        /* background-repeat: no-repeat;
        background-attachment: fixed; */
        height: 500px;
        margin: 120px 0px 80px 0px;
    }

    .sectionPartners p {
        color: #000000;
        font-size: 1.4rem;
        left: 40px;
        position: relative;
        top: 140px;
        text-align: center;
        width: 300px;
    }

    .btnLinkPartners {
        background-color: transparent;
        border: none;
        border: 4px solid #0846e3;
        color: #000000;
        cursor: pointer;
        font-size: 1.3rem;
        left: 40px;
        padding: 14px;
        position: relative;
        top: 250px;
        transition: all 0.8s ease-in;
        width: 300px;
    }

    .btnLinkPartners:hover {
        background-color: #0846e3;
    }
}