.contactTitle{
    background-color: #00122B;
    margin: -16px 0px;
    padding: 40px 40px 180px 40px;
    text-transform: uppercase;
}

.contactTitle h2{
    color: #0846e3;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.contactTitle p{
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 0px 300px;
}

.sectionContact{
    margin: 40px;
    display: grid;
    grid-template-columns: 60% 40%;
}

.formContact{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 30px #000000;
    border-radius: 10px;
    height: 450px;
    left: 10%;
    padding: 40px;
    position: relative;
    bottom: 100px;
    width: 60%;
    animation: formShow 1s ease-in;
}

.containerInput{
    padding:10px;
    width: 430px;
}

.formContact label{
    font-size: 1.4rem;
    color: #000000;
    position: relative;
    left: 10px;
    top: -70px;
    transition: 0.4s ease-in-out all;
}

.formContact .inputsForm {
    border: none;
    border-bottom: 3px solid #0846e3;
    color: #4f4f4f;
    font-size: 1rem;
    margin: 12px 0px;
    padding: 2px;
    width: 100%;
}

.formContact .inputsForm:focus~label {
    top: -80px;
}

.formContact .inputsForm:focus {
    outline: none;
}

.containerBtn{
    text-align: center;
}

.btnSendMessage{
    background-color: #0846e3;
    border-radius:16px;
    border: none;
    box-shadow: 0px 6px 1px #696969;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    padding:16px 60px;
    text-transform: uppercase;
}

.btnSendMessage:hover{
    background-color: #0d3e82;
}

.btnSendMessage:active{
    top: 4px;
    box-shadow: 0px 0px 0px #696969;
}

.textInformative{
    background-color: #00122B;
    color: #ffffff;
    font-size: 1.4rem;
    margin:80px 0px;
    padding: 40px;
    width: 80%;
    text-align: center;
}

.textInformative span{
    color: #0453c1;

}

.messageLocation{
    background-color: #00122B;
    padding: 40px;
    text-align: center;
    color: #ffffff;
    font-size: 2rem;
}

.icon-location{
    color: #10f804;
}

.iframeContact{
    border: none;
    height: 400px;
    width: 100%;
}

.sectionInfoContact{
    margin-top:80px;
}

.sectionInfoContact h3{
    font-size:1.6rem;
    text-decoration: overline;
    text-decoration: underline;
    text-align:center;
}

.sectionsInfo{
    margin: 40px;
}

.sectionsInfo h4{
    margin: 20px;
    font-size:1.2rem;
}

.sectionsInfo h4 span{
    margin: 20px;
    font-size:1.8rem;
    color: #0846e3;
}

.sectionsInfo p{
    margin: 20px 40px;
    font-size:1rem;
    color: #4f4f4f;
}

.alertForm{
    background-color: #0846e3;
    border-radius: 6px;
    color: #ffffff;
    font-size: 1.4rem;
    height: 200px;
    left: 30%;
    position: fixed;
    text-align: center;
    top: 30%;
    width: 500px;
    z-index: 1;
}

.icon-forward{
    font-size: 4rem;
    color: rgb(13, 237, 20);
}

/* Animation form */


@keyframes formShow {
    0%{
        transform: translateX(-600px);
    }

    100%{
        transform: translateX(0px);
    }
}

@media only screen and (max-width: 2000px) and (min-width: 1600px) {

    .contactTitle{
        background-color: #00122B;
        margin: -16px 0px;
        padding: 40px 20px 180px 20px;
    }
    
    .contactTitle h2{
        color: #0846e3;
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
    }
    
    .contactTitle p{
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
        padding: 0px 400px;
    }
    
    .sectionContact{
        margin: 40px;
        display: grid;
        grid-template-columns: 60% 40%;
    }
    
    .formContact{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 30px #000000;
        border-radius: 10px;
        height: 450px;
        left: 10%;
        padding: 40px;
        position: relative;
        bottom: 100px;
        width: 70%;
        animation: formShow 1s ease-in;
    }
    
    .containerInput{
        padding:10px;
        width: 380px;
    }
    
    .formContact label{
        font-size: 1.4rem;
        color: #000000;
        position: relative;
        left: 10px;
        top: -70px;
        transition: 0.4s ease-in-out all;
    }
    
    .formContact .inputsForm {
        border: none;
        border-bottom: 3px solid #0846e3;
        color: #4f4f4f;
        font-size: 1rem;
        margin: 12px 0px;
        padding: 2px;
        width: 160%;
    }
    
    .formContact .inputsForm:focus~label {
        top: -80px;
    }
    
    .formContact .inputsForm:focus {
        outline: none;
    }
    
    .containerBtn{
        text-align: center;
    }
    
    .btnSendMessage{
        background-color: #0846e3;
        border-radius:16px;
        border: none;
        box-shadow: 0px 6px 1px #696969;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1rem;
        position: relative;
        padding:16px 60px;
        text-transform: uppercase;
    }
    
    .btnSendMessage:hover{
        background-color: #0d3e82;
    }
    
    .btnSendMessage:active{
        top: 4px;
        box-shadow: 0px 0px 0px #696969;
    }
    
    .textInformative{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        margin:80px 0px;
        padding: 40px;
        width: 80%;
        text-align: center;
    }
    
    .textInformative span{
        color: #0453c1;
    }
    
    .messageLocation{
        background-color: #00122B;
        padding: 40px;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
    }
    
    .icon-location{
        color: #10f804;
    }
    
    .iframeContact{
        border: none;
        height: 400px;
        width: 100%;
    }
    
    .sectionInfoContact{
        margin-top:80px;
    }
    
    .sectionInfoContact h3{
        font-size:2.2rem;
        text-decoration: overline;
        text-decoration: underline;
        text-align:center;
    }
    
    .sectionsInfo{
        margin: 40px;
    }
    
    .sectionsInfo h4{
        margin: 20px;
        font-size:1.6rem;
    }
    
    .sectionsInfo h4 span{
        margin: 20px;
        font-size:2rem;
        color: #0846e3;
    }
    
    .sectionsInfo p{
        margin: 20px 40px;
        font-size:1.2rem;
        color: #4f4f4f;
    }
    
    .alertForm{
        background-color: #0846e3;
        border-radius: 6px;
        color: #ffffff;
        font-size: 1.4rem;
        height: 200px;
        left: 30%;
        position: fixed;
        text-align: center;
        top: 30%;
        width: 500px;
        z-index: 1;
    }
    
    .icon-forward{
        font-size: 4rem;
        color: rgb(13, 237, 20);
    }

}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {

    .contactTitle{
        background-color: #00122B;
        margin: -36px 0px;
        padding: 40px 20px 180px 20px;
    }
    
    .contactTitle h2{
        color: #0846e3;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
    }
    
    .contactTitle p{
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        padding: 0px 200px;
    }
    
    .sectionContact{
        margin: 40px;
        display: grid;
        grid-template-columns: 60% 40%;
    }
    
    .formContact{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 30px #000000;
        border-radius: 10px;
        height: 450px;
        left: 10%;
        padding: 40px;
        position: relative;
        bottom: 100px;
        width: 70%;
        animation: formShow 1s ease-in;
    }
    
    .containerInput{
        padding:10px;
        width: 430px;
    }
    
    .formContact label{
        font-size: 1.4rem;
        color: #000000;
        position: relative;
        left: 10px;
        top: -70px;
        transition: 0.4s ease-in-out all;
    }
    
    .formContact .inputsForm {
        border: none;
        border-bottom: 3px solid #0846e3;
        color: #4f4f4f;
        font-size: 1rem;
        margin: 12px 0px;
        padding: 2px;
        width: 90%;
    }
    
    .formContact .inputsForm:focus~label {
        top: -80px;
    }
    
    .formContact .inputsForm:focus {
        outline: none;
    }
    
    .containerBtn{
        text-align: center;
    }
    
    .btnSendMessage{
        background-color: #0846e3;
        border-radius:16px;
        border: none;
        box-shadow: 0px 6px 1px #696969;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1rem;
        position: relative;
        padding:16px 60px;
        text-transform: uppercase;
    }
    
    .btnSendMessage:hover{
        background-color: #0d3e82;
    }
    
    .btnSendMessage:active{
        top: 4px;
        box-shadow: 0px 0px 0px #696969;
    }
    
    .textInformative{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        margin:80px 0px;
        padding: 40px;
        width: 80%;
        text-align: center;
    }
    
    .textInformative span{
        color: #0453c1;
    
    }
    
    .messageLocation{
        background-color: #00122B;
        padding: 40px;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
    }
    
    .icon-location{
        color: #10f804;
    }
    
    .iframeContact{
        border: none;
        height: 400px;
        width: 100%;
    }
    
    .sectionInfoContact{
        margin-top:80px;
    }
    
    .sectionInfoContact h3{
        font-size:1.6rem;
        text-decoration: overline;
        text-decoration: underline;
        text-align:center;
    }
    
    .sectionsInfo{
        margin: 40px;
    }
    
    .sectionsInfo h4{
        margin: 20px;
        font-size:1.2rem;
    }
    
    .sectionsInfo h4 span{
        margin: 20px;
        font-size:1.8rem;
        color: #0846e3;
    }
    
    .sectionsInfo p{
        margin: 20px 40px;
        font-size:1rem;
        color: #4f4f4f;
    }
    
    .alertForm{
        background-color: #0846e3;
        border-radius: 6px;
        color: #ffffff;
        font-size: 1.4rem;
        height: 200px;
        left: 30%;
        position: fixed;
        text-align: center;
        top: 30%;
        width: 500px;
        z-index: 1;
    }
    
    .icon-forward{
        font-size: 4rem;
        color: rgb(13, 237, 20);
    }
    




}

@media only screen and (max-width: 999px) and (min-width: 800px) {

    .contactTitle{
        background-color: #00122B;
        margin: 10px 0px;
        padding: 40px 20px 180px 20px;
    }
    
    .contactTitle h2{
        color: #0846e3;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
    }
    
    .contactTitle p{
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        padding: 0px 0px;
    }
    
    .sectionContact{
        margin: 40px;
        display: grid;
        grid-template-columns: 60% 40%;
    }
    
    .formContact{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 30px #000000;
        border-radius: 10px;
        height: 450px;
        left: 10%;
        padding: 40px;
        position: relative;
        bottom: 100px;
        width: 60%;
        animation: formShow 1s ease-in;
    }
    
    .containerInput{
        padding:10px;
        width: 300px;
    }
    
    .formContact label{
        font-size: 1.4rem;
        color: #000000;
        position: relative;
        left: 10px;
        top: -70px;
        transition: 0.4s ease-in-out all;
    }
    
    .formContact .inputsForm {
        border: none;
        border-bottom: 3px solid #0846e3;
        color: #4f4f4f;
        font-size: 1rem;
        margin: 12px 0px;
        padding: 2px;
        width: 90%;
    }
    
    .formContact .inputsForm:focus~label {
        top: -80px;
    }
    
    .formContact .inputsForm:focus {
        outline: none;
    }
    
    .containerBtn{
        text-align: center;
    }
    
    .btnSendMessage{
        background-color: #0846e3;
        border-radius:16px;
        border: none;
        box-shadow: 0px 6px 1px #696969;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1rem;
        position: relative;
        padding:16px 60px;
        text-transform: uppercase;
    }
    
    .btnSendMessage:hover{
        background-color: #0d3e82;
    }
    
    .btnSendMessage:active{
        top: 4px;
        box-shadow: 0px 0px 0px #696969;
    }
    
    .textInformative{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        margin:80px 0px;
        padding: 40px;
        width: 80%;
        text-align: center;
    }
    
    .textInformative span{
        color: #0453c1;
    
    }
    
    .messageLocation{
        background-color: #00122B;
        padding: 40px;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
    }
    
    .icon-location{
        color: #10f804;
    }
    
    .iframeContact{
        border: none;
        height: 400px;
        width: 100%;
    }
    
    .sectionInfoContact{
        margin-top:80px;
    }
    
    .sectionInfoContact h3{
        font-size:1.5rem;
        text-decoration: overline;
        text-decoration: underline;
        text-align:center;
    }
    
    .sectionsInfo{
        margin: 40px 0px;
    }
    
    .sectionsInfo h4{
        margin: 20px;
        font-size:1.2rem;
    }
    
    .sectionsInfo h4 span{
        margin: 20px;
        font-size:1.8rem;
        color: #0846e3;
    }
    
    .sectionsInfo p{
        margin: 20px 40px;
        font-size:1rem;
        color: #4f4f4f;
    }
    
    .alertForm{
        background-color: #0846e3;
        border-radius: 6px;
        color: #ffffff;
        font-size: 1.4rem;
        height: 200px;
        left: 30%;
        position: fixed;
        text-align: center;
        top: 30%;
        width: 500px;
        z-index: 1;
    }
    
    .icon-forward{
        font-size: 4rem;
        color: rgb(13, 237, 20);
    }
}

@media only screen and (max-width: 799px) and (min-width: 500px) {

    .contactTitle{
        background-color: #00122B;
        margin: 10px 0px;
        padding: 40px 20px 180px 20px;
    }
    
    .contactTitle h2{
        color: #0846e3;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
    }
    
    .contactTitle p{
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        padding: 0px 0px;
    }
    
    .sectionContact{
        margin: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .formContact{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 30px #000000;
        border-radius: 10px;
        height: 450px;
        left: 10%;
        padding: 40px;
        position: relative;
        bottom: 100px;
        width: 70%;
        animation: formShow 1s ease-in;
    }
    
    .containerInput{
        padding:10px;
        width: 500px;
    }
    
    .formContact label{
        font-size: 1.4rem;
        color: #000000;
        position: relative;
        left: 10px;
        top: -70px;
        transition: 0.4s ease-in-out all;
    }
    
    .formContact .inputsForm {
        border: none;
        border-bottom: 3px solid #0846e3;
        color: #4f4f4f;
        font-size: 1rem;
        margin: 12px 0px;
        padding: 2px;
        width: 90%;
    }
    
    .formContact .inputsForm:focus~label {
        top: -80px;
    }
    
    .formContact .inputsForm:focus {
        outline: none;
    }
    
    .containerBtn{
        text-align: center;
    }
    
    .btnSendMessage{
        background-color: #0846e3;
        border-radius:16px;
        border: none;
        box-shadow: 0px 6px 1px #696969;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1rem;
        position: relative;
        padding:16px 60px;
        text-transform: uppercase;
    }
    
    .btnSendMessage:hover{
        background-color: #0d3e82;
    }
    
    .btnSendMessage:active{
        top: 4px;
        box-shadow: 0px 0px 0px #696969;
    }
    
    .textInformative{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        margin:20px;
        padding: 40px;
        width: 80%;
        text-align: center;
    }
    
    .textInformative span{
        color: #0453c1;
    
    }
    
    .messageLocation{
        background-color: #00122B;
        padding: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
    }
    
    .icon-location{
        color: #10f804;
    }
    
    .iframeContact{
        border: none;
        height: 400px;
        width: 100%;
    }
    
    .sectionInfoContact{
        margin-top:80px;
    }
    
    .sectionInfoContact h3{
        font-size:2rem;
        text-decoration: overline;
        text-decoration: underline;
        text-align:center;
    }
    
    .sectionsInfo{
        margin:0px 100px;
        width:400px;
        text-align:center;
    }
    
    .sectionsInfo h4{
        margin: 0px;
        font-size:1.2rem;
    }
    
    .sectionsInfo h4 span{
        margin: 20px;
        font-size:1.8rem;
        color: #0846e3;
    }
    
    .sectionsInfo p{
        margin: 20px 40px;
        font-size:1rem;
        color: #4f4f4f;
    }
    
    .alertForm{
        background-color: #0846e3;
        border-radius: 6px;
        color: #ffffff;
        font-size: 1.4rem;
        height: 200px;
        left: 30%;
        position: fixed;
        text-align: center;
        top: 30%;
        width: 500px;
        z-index: 1;
    }
    
    .icon-forward{
        font-size: 4rem;
        color: rgb(13, 237, 20);
    }
}

@media only screen and (max-width: 499px) and (min-width: 250px) {

    .contactTitle{
        background-color: #00122B;
        margin: 10px 0px;
        padding: 40px 20px 180px 20px;
    }
    
    .contactTitle h2{
        color: #0846e3;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
    }
    
    .contactTitle p{
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        padding: 0px 0px;
    }
    
    .sectionContact{
        margin: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .formContact{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 30px #000000;
        border-radius: 10px;
        height: 400px;
        left: 10%;
        padding: 40px;
        position: relative;
        bottom: 100px;
        width: 60%;
        animation: formShow 1s ease-in;
    }
    
    .containerInput{
        padding:20px 0px;
        width: 100px;
    }
    
    .formContact label{
        font-size: 1rem;
        color: #000000;
        position: relative;
        left: 20px;
        top: -50px;
        transition: 0.4s ease-in-out all;
    }
    
    .formContact .inputsForm {
        border: none;
        border-bottom: 3px solid #0846e3;
        color: #4f4f4f;
        font-size: 1rem;
        margin: 2px 0px;
        padding: 2px;
        width: 220%;
    }
    
    .formContact .inputsForm:focus~label {
        top: -80px;
    }
    
    .formContact .inputsForm:focus {
        outline: none;
    }
    
    .containerBtn{
        text-align: center;
    }
    
    .btnSendMessage{
        background-color: #0846e3;
        border-radius:16px;
        border: none;
        box-shadow: 0px 6px 1px #696969;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-size: 1rem;
        position: relative;
        padding:16px 60px;
        text-transform: uppercase;
    }
    
    .btnSendMessage:hover{
        background-color: #0d3e82;
    }
    
    .btnSendMessage:active{
        top: 4px;
        box-shadow: 0px 0px 0px #696969;
    }
    
    .textInformative{
        background-color: #00122B;
        color: #ffffff;
        font-size: 1.4rem;
        margin:20px;
        padding: 40px;
        width: 80%;
        text-align: center;
    }
    
    .textInformative span{
        color: #0453c1;
    
    }
    
    .messageLocation{
        background-color: #00122B;
        padding: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
    }
    
    .icon-location{
        color: #10f804;
    }
    
    .iframeContact{
        border: none;
        height: 400px;
        width: 100%;
    }
    
    .sectionInfoContact{
        margin-top:80px;
    }
    
    .sectionInfoContact h3{
        font-size:1.4rem;
        text-decoration: overline;
        text-decoration: underline;
        text-align:center;
    }
    
    .sectionsInfo{
        margin:0px 40px;
        width:300px;
        text-align:center;
    }
    
    .sectionsInfo h4{
        margin: 40px;
        font-size:1rem;
    }
    
    .sectionsInfo h4 span{
        margin: 0px;
        font-size:1.2rem;
        color: #0846e3;
    }
    
    .sectionsInfo p{
        margin: 20px 20px;
        font-size:1rem;
        color: #4f4f4f;
    }
    
    .alertForm{
        background-color: #0846e3;
        border-radius: 6px;
        color: #ffffff;
        font-size: 1.4rem;
        height: 200px;
        left: 30%;
        position: fixed;
        text-align: center;
        top: 30%;
        width: 500px;
        z-index: 1;
    }
    
    .icon-forward{
        font-size: 4rem;
        color: rgb(13, 237, 20);
    }
}