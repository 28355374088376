
.containerFooter{
    background-color: #00122B;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.sectionFooter{
    margin: 40px;
}

.lofoFooter {
    left: 0px;
    position: relative;
    top: 40px;
    width: 350px;
}

.sectionFooter2{
    margin: 10px;
    text-align: center;
    position: relative;
    right: 100px;
}

.sectionFooter2 p{
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
}

.sectionFooter2 iframe{
    border: none;
    border-radius: 8px;
    height: 200px;
    width: 340px;
}

.sectionFooter3{
    position: relative;
    right: 100px;
    top: 60px;
}

.sectionFooter3 label{
    color: #0b85c2;
    font-size: 1.3rem;
    font-weight: bold;
}

.sectionFooter3 p{
    color: #ffffff;
    font-size: 1rem;
    padding-left: 20px;
}

.socialMediaFooter{
    margin-top: 40px;
    border-radius: 20px;
    text-align: center;
}

.socialMediaFooter a span{ 
    box-shadow: 0px 6px 0px #616161;
    background-color: #ffffff;
    border-radius: 100%;
    padding: 4px;
    margin: 8px;
}

.socialMediaFooter a span:hover{ 
    box-shadow: 0px 6px 0px #2d2d2d;
}

.copy{
    color: #00122B;
    padding-top: 10px;
    text-align: center;
}

@media only screen and (max-width: 999px) and (min-width: 768px) {

    .containerFooter{
        background-color: #00122B;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
    }
    
    .sectionFooter{
        margin: 20px;
    }
    
    .lofoFooter {
        left: 20px;
        position: relative;
        top: 60px;
        width: 200px;
    }
    
    .sectionFooter2{
        margin: 10px;
        left: 10px;
        text-align: center;
    }
    
    .sectionFooter2 p{
        color: #ffffff;
        font-size: 0.960rem;
    }
    
    .sectionFooter2 iframe{
        border: none;
        border-radius: 8px;
        height: 200px;
        width: 200px;
    }
    
    .sectionFooter3{
        position: relative;
        right: 30px;
        top: 60px;
    }
    
    .sectionFooter3 label{
        color: #0b85c2;
        font-size: 1rem
    }
    
    .sectionFooter3 p{
        color: #ffffff;
        font-size: 0.800rem;
        padding-left: 20px;
    }
    
    .socialMediaFooter{
        margin-top: 40px;
        border-radius: 20px;
        text-align: center;
    }
    
    .socialMediaFooter a span{ 
        box-shadow: 0px 6px 0px #616161;
        background-color: #ffffff;
        border-radius: 100%;
        padding: 4px;
        margin: 8px;
    }
    
    .socialMediaFooter a span:hover{ 
        box-shadow: 0px 6px 0px #2d2d2d;
    }
    
    .copy{
        color: #00122B;
        padding-top: 10px;
        text-align: center;
    }
    
}


@media only screen and (max-width: 767px) and (min-width: 250px) {

    .containerFooter{
        background-color: #00122B;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0px 120px 0px;
        width: 100%;
    }
    
    .sectionFooter{
        margin: 40px;
    }
    
    .lofoFooter {
        left: 30px;
        position: relative;
        top: 0px;
        width: 250px;
    }
    
    .sectionFooter2{
        left: 0px;
        text-align: center;
    }
    
    .sectionFooter2 p{
        color: #ffffff;
        font-size: 1.2rem;
    }
    
    .sectionFooter2 iframe{
        border: none;
        border-radius: 8px;
        height: 300px;
        width: 380px;
    }
    
    .sectionFooter3{
        position: relative;
        left: 0px;
        top: 60px;
        text-align: center;
    }
    
    .sectionFooter3 label{
        color: #0b85c2;
        font-size: 1.4rem;
    }
    
    .sectionFooter3 p{
        color: #ffffff;
        font-size: 1.2rem;
        padding-left: 20px;
    }
    
    .socialMediaFooter{
        margin-top: 60px;
        border-radius: 20px;
        text-align: center;
    }
    
    .socialMediaFooter a span{ 
        box-shadow: 0px 6px 0px #616161;
        background-color: #ffffff;
        border-radius: 100%;
        padding: 4px;
        margin: 8px;
    }
    
    .socialMediaFooter a span:hover{ 
        box-shadow: 0px 6px 0px #2d2d2d;
    }
    
    .copy{
        color: #00122B;
        padding-top: 10px;
        text-align: center;
    }
    
}